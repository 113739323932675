.a-container {
  height: 48vh;
  margin: 0px 3vw 0px 3vw; 
}

.a-list-desc {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 400;

  margin-left: 18px;

  color: var(--gray);
}

.a-desc {
  margin-top: 4vh;
}

.a-mail {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.a-mail-link {
  text-decoration: none;
  color: var(--lightgray);
}
.a-mail-link:hover {
  color: var(--gray);
}

.a-desc-delay {
  transition-delay: 1.8s;
}

@media screen and (max-width: 992px) {
  .a-container {
    height: min-content;
    margin: 0px 24px 0px 24px; 
  }
  .a-list-desc {
    display: none;
  }
  .a-desc {
    margin-top: 40px;
  }
  .a-mail {
    margin-top: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}